<template>
<div>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="@/assets/images/logo/ford-logo.png"
            alt="logo"
          >
        </b-link>

        <b-card-title class="mb-1">
          Logowanie
        </b-card-title>
        <b-card-text class="mb-2">
          Zaloguj się, by uzyskać dostęp do szkoleń oraz materiałów szkoleniowych
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                  tabindex="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Hasło</label>
                <b-link to="/forgot-password">
                  <small>Przypomnij hasło</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    tabindex="2"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid || loading"
            >
              Logowanie
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          Jeśli nie masz jeszcze konta, skontaktuj się ze swoim Przełożonym lub <b-link
            href="mailto:biuro@artpm.pl"
          >
            Administratorem
            strony</b-link></b-card-text>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
<center><img src="https://artpm-automotive.pl/wp-content/themes/adv/img/dofinansowanie.png" style="width: 80%; max-width:700px;">
</center>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ToastificationInactiveAccount from './ToastificationInactiveAccount.vue'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      loading: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    alreadyLoggedIn() {
      return this.$store.state.auth.userData !== null
    },
  },
  mounted() {
    if (this.alreadyLoggedIn) {
      this.redirectAfterLogin()
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(valid => {
        if (valid) {
          this.loading = true
          return this.$store.dispatch('auth/login', {
            email: this.userEmail,
            password: this.password,
          }).then(loginResponse => {
            if (loginResponse.status === 204) {
              this.$store.dispatch('auth/fetchUserData').then(userResponse => {
                if (userResponse.status === 200) {
                  this.redirectAfterLogin()
                } else {
                  this.$toast({
                    timeout: false,
                    component: ToastificationContent,
                    props: {
                      title: 'Błąd pobierania danych',
                      text: 'Upewnij się, że cookies (w tym "stron trzecich") nie są zablokowane w Twojej przeglądarce,',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
            }
            if (loginResponse.status === 401) {
              if (loginResponse.data && loginResponse.data.message === 'Your account is not activated.') {
                this.$toast({
                  component: ToastificationInactiveAccount, props: { email: this.userEmail },
                }, { timeout: false })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Błędne dane logowania',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                }, { timeout: false })
              }
            }
          }).finally(() => {
            this.loading = false
          })
        } return false
      })
    },

    redirectAfterLogin() {
      return this.$router.replace({ path: this.$route.query.to || '/' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
